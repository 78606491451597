import React from 'react';
import { FormProvider } from 'react-hook-form';
import toast from 'react-hot-toast';

export default function Form({ children, form: { handleSubmit, ...form }, onSubmit, ...props }) {
  const { errors } = form.formState;

  React.useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast.error('Please fix the errors below');
    }
  }, [errors]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} {...props}>
        {children}
      </form>
    </FormProvider>
  );
}
