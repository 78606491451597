import { useEffect, useState } from 'react';
import { createStorage } from 'unstorage';
import localStorageDriver from 'unstorage/drivers/localstorage';

const useStorage = () => {
  const [storage, setStorage] = useState(null);

  useEffect(() => {
    setStorage(
      createStorage({
        driver: localStorageDriver({ base: 'MTGProxies:' }),
      })
    );
  }, []);

  return {
    storage,
  };
};

export default useStorage;
