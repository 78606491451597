import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon, TrashIcon, PrinterIcon, CheckCircleIcon } from '@heroicons/react/outline';
import { useCart } from '../hooks/useCart';
import Button from './Button';
import usePDF from '../hooks/usePDF';
import Card from './Card';
import ActivityIndicator from './ActivityIndicator';
import FormSwitch from './FormSwitch';
import FormSelect from './FormSelect';

export default function Cart() {
  const { cartOpened, closeCart, cart, clearCart } = useCart();
  const { downloadPDF, downloadStatus, padding, setPadding, format, setFormat, scale, setScale } = usePDF();

  const onPrint = () => {
    downloadPDF(cart);
  };

  const CartHeader = () => {
    return (
      <div className='px-4 sm:px-6'>
        <div className='flex items-start justify-between'>
          <div className='flex items-center space-x-2'>
            <Dialog.Title className='text-lg font-medium text-gray-900'>Print List</Dialog.Title>
          </div>
          <div className='flex items-center ml-3 h-7'>
            <Button unstyled className='text-gray-400 rounded-md hover:text-gray-500' testId='navbar-cart-close' onClick={closeCart}>
              <span className='sr-only'>Close panel</span>
              <XIcon className='w-6 h-6' aria-hidden='true' />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const CartList = () => {
    return (
      <ul className='grid grid-cols-2 gap-3 lg:grid-cols-3'>
        {cart
          .filter((card) => !!card?.details)
          .map((card) => (
            <li key={card.details.id}>
              <Card card={card.details} showActiveStyles={false} />
            </li>
          ))}
      </ul>
    );
  };

  const CartEmpty = () => {
    return (
      <div>
        <p className='text-gray-500'>No proxies in the print list.</p>
      </div>
    );
  };

  return (
    <Transition.Root show={cartOpened} as={Fragment}>
      <Dialog as='div' className='fixed inset-0 z-50 overflow-hidden' onClose={closeCart}>
        <div className='absolute inset-0 overflow-hidden'>
          <Dialog.Overlay className='absolute inset-0' />

          <div className='fixed inset-y-0 right-0 flex max-w-full'>
            <Transition.Child
              as={Fragment}
              enter='transform transition ease-in-out duration-500'
              enterFrom='translate-x-full'
              enterTo='translate-x-0'
              leave='transform transition ease-in-out duration-500'
              leaveFrom='translate-x-0'
              leaveTo='translate-x-full'
            >
              <div className='relative w-screen md:max-w-xl'>
                <div className='flex flex-col h-full pt-6 pb-48 overflow-y-scroll shadow-xl bg-gray-50'>
                  <CartHeader />
                  <div className='relative flex-1 px-4 mt-6 sm:px-6'>{cart.length > 0 ? <CartList /> : <CartEmpty />}</div>
                  {cart.length > 0 && (
                    <div className='fixed bottom-0 right-0 z-50 flex flex-col flex-shrink-0 w-full max-w-xl px-4 py-4 bg-white'>
                      <div className='flex flex-col items-center justify-between mb-5 md:flex-row'>
                        <div className='flex items-center mb-3 md:mb-0'>
                          <FormSwitch label='Add padding' enabled={padding} setEnabled={setPadding} />
                        </div>
                        <div className='flex items-center space-x-4'>
                          <FormSelect label='Paper size' value={format} setValue={setFormat} options={['A4', 'Letter', 'Legal']} />
                          <FormSelect label='Scale' value={scale} setValue={setScale} options={['90%', '100%', '110%']} />
                        </div>
                      </div>
                      <div className='flex items-center justify-end space-x-5'>
                        <Button unstyled onClick={clearCart} className='group'>
                          <span className='sr-only'>Clear card list</span>
                          <TrashIcon className='w-5 h-5 text-gray-400 transition-colors duration-150 hover:text-gray-500' aria-hidden='true' />
                        </Button>
                        <Button onClick={onPrint} testId='print-pdf'>
                          <div style={{ width: 140, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {downloadStatus === 'pending' ? (
                              <ActivityIndicator size={18} />
                            ) : (
                              <div className='flex items-center space-x-3'>
                                {downloadStatus === 'success' ? (
                                  <>
                                    <CheckCircleIcon className='w-5 h-5' aria-hidden='true' />
                                    <span>Success!</span>
                                  </>
                                ) : (
                                  <>
                                    <PrinterIcon className='w-5 h-5' aria-hidden='true' />
                                    <span>Print</span>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
