const toDataUrl = (src) => {
  return new Promise((resolve, reject) => {
    // Create an Image object
    var img = new Image();
    // Add CORS approval to prevent a tainted canvas
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
      try {
        // Create an html canvas element
        var canvas = document.createElement('CANVAS');
        // Create a 2d context
        var ctx = canvas.getContext('2d');
        var dataURL;
        // Resize the canavas to the image dimensions
        canvas.height = this.height;
        canvas.width = this.width;
        // Draw the image to a canvas
        ctx.drawImage(this, 0, 0);
        // Convert the canvas to a data url
        dataURL = canvas.toDataURL('image/png');
        // Return the data url via callback
        resolve(dataURL);
        // Mark the canvas to be ready for garbage
        // collection
        canvas = null;
      } catch (error) {
        reject(error);
      }
    };
    // Load the image
    img.src = src;
  });
};

export default toDataUrl;
