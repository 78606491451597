import { MenuIcon, PrinterIcon, QuestionMarkCircleIcon, SearchIcon } from '@heroicons/react/outline';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Container from '../components/Container';
import { useCart } from '../hooks/useCart';
import Button from './Button';
import Form from './Form';
import Logo from './Logo';
import MobileDrawer from './MobileDrawer';
import Badge from './Badge';

export default function Navbar() {
  const router = useRouter();
  const { openCart, getNumberOfItemsInCart } = useCart();
  const [mobileDrawerActive, setMobileDrawerActive] = useState(false);

  const form = useForm();

  const handleSubmit = (data) => {
    router.push({
      pathname: '/search',
      query: { s: data.search },
    });
  };

  return (
    <div className='py-4 bg-gray-900 text-gray-50'>
      <Container className='flex justify-between'>
        <div className='flex items-center space-x-6'>
          <div>
            <Link href='/' passHref>
              <Logo />
            </Link>
          </div>
          <nav className='hidden xl:block'>
            <ul className='flex items-center space-x-8'>
              <li>
                <Link href='/sets' className={`${router.pathname == '/sets' ? 'text-green-500' : ''} font-medium hover:text-green-500 transition-colors duration-150`} data-test-id='navbar-link-sets'>
                  Sets
                </Link>
              </li>
              <li>
                <Link href='/tokens' className={`${router.pathname == '/tokens' ? 'text-green-500' : ''} font-medium hover:text-green-500 transition-colors duration-150`} data-test-id='navbar-link-tokens'>
                  Tokens
                </Link>
              </li>
              <li>
                <Link href='/emblems' className={`${router.pathname == '/emblems' ? 'text-green-500' : ''} font-medium hover:text-green-500 transition-colors duration-150`} data-test-id='navbar-link-emblems'>
                  Emblems
                </Link>
              </li>
              <li className='flex items-centers gap-2'>
                <Link href='/import' className={`${router.pathname == '/import' ? 'text-green-500' : ''} font-medium hover:text-green-500 transition-colors duration-150`} data-test-id='navbar-link-import'>
                  Import
                </Link>
              </li>
              {process.env.NEXT_PUBLIC_DECK_LISTS_LIVE === 'true' && (
                <li className='flex items-centers gap-2'>
                  <Link href='/deck-lists' className={`${router.pathname == '/deck-lists' ? 'text-green-500' : ''} font-medium hover:text-green-500 transition-colors duration-150`} data-test-id='navbar-link-deck-lists'>
                    Deck Lists
                  </Link>
                  <Badge>New</Badge>
                </li>
              )}
              {process.env.NEXT_PUBLIC_TOP_PROXIES_LIVE === 'true' && (
                <li className='flex items-centers gap-2'>
                  <Link href='/top-proxies' className={`${router.pathname == '/top-proxies' ? 'text-green-500' : ''} font-medium hover:text-green-500 transition-colors duration-150`} data-test-id='navbar-link-top-proxies'>
                    Top Proxies
                  </Link>
                  <Badge>New</Badge>
                </li>
              )}
              <li>
                <Link href='/articles' className={`${router.pathname == '/articles' ? 'text-green-500' : ''} font-medium hover:text-green-500 transition-colors duration-150`} data-test-id='navbar-link-articles'>
                  Articles
                </Link>
              </li>
            </ul>
          </nav>
          <div className='hidden md:flex flex-1 justify-center px-2 xl:ml-6 xl:justify-end'>
            <div className='w-full max-w-lg xl:max-w-xs'>
              <label htmlFor='search' className='sr-only'>
                Search
              </label>
              <Form className='relative' form={form} onSubmit={handleSubmit}>
                <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                  <SearchIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                </div>
                <input
                  id='search'
                  name='search'
                  className='block w-full rounded-md border-0 bg-gray-700 py-1.5 pl-10 pr-3 text-gray-300 placeholder:text-gray-400 focus:bg-white focus:text-gray-900 focus:ring-0 sm:text-sm sm:leading-6'
                  placeholder='Search'
                  type='search'
                  {...form.register('search')}
                />
              </Form>
            </div>
          </div>
        </div>

        <nav className='items-center hidden xl:flex'>
          <ul className='flex items-center space-x-6'>
            <li>
              <Link
                href='https://www.buymeacoffee.com/zacminner'
                className='flex items-center justify-center px-3 py-1 h-[36px] text-sm font-medium transition-colors duration-150 bg-green-900 bg-opacity-50 border border-green-800 rounded-md text-green-50 hover:bg-opacity-75'
                target='_blank'
              >
                Donate
              </Link>
            </li>
            <li>
              <Link href='/support' className={`text-gray-400 font-medium hover:text-green-500 transition-colors duration-150`} data-test-id='navbar-link-support'>
                <span className='sr-only'>Support</span>
                <QuestionMarkCircleIcon width={24} className={`${router.pathname == '/support' ? 'text-green-500' : ''}`} />
              </Link>
            </li>
            <li>
              <button onClick={openCart} className='flex items-center justify-center group' data-test-id='navbar-cart-open'>
                <PrinterIcon width={24} className='text-gray-400 transition-colors duration-150 group-hover:text-green-500' />
                <div className='font-semibold w-[25px] text-right group-hover:text-green-500 transition-colors duration-150'>{getNumberOfItemsInCart()}</div>
              </button>
            </li>
          </ul>
        </nav>
        <div className='flex items-center space-x-5 xl:hidden'>
          <button onClick={openCart} className='flex items-center justify-center'>
            <PrinterIcon width={20} className='text-gray-400' />
            <div className='font-semibold w-[25px] text-right'>{getNumberOfItemsInCart()}</div>
          </button>

          <Button onClick={() => setMobileDrawerActive(true)} unstyled>
            <MenuIcon width={24} className='text-gray-400' />
          </Button>
        </div>
        <MobileDrawer active={mobileDrawerActive} setActive={setMobileDrawerActive} />
      </Container>
    </div>
  );
}
