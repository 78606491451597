const CONFIG = {
  title: 'MTG Proxies',
};

export const STORAGE_KEYS = {
  CART: 'Cart',
  PDF_PADDING: 'PDF_Padding',
  PDF_FORMAT: 'PDF_Format',
  PDF_SCALE: 'PDF_Scale',
};

export const PAGES = {
  HOME: {
    title: `${CONFIG.title} - Proxy Magic: The Gathering cards`,
    description: 'Access a vast library of Magic: The Gathering proxy cards. Easily view and print MTG card images for casual play. Start building the deck of your dreams now!',
    twitter_image: 'https://www.mtg-proxies.com/share-image-twitter.png',
  },
  SETS: {
    title: `Card Sets - ${CONFIG.title}`,
    description: 'Browse through extensive MTG card sets and select proxies for your deck. Utilize a rich database to print your desired Magic: The Gathering cards for casual gaming.',
    twitter_image: 'https://www.mtg-proxies.com/share-image-twitter.png',
  },
  TOKENS: {
    title: `Tokens - ${CONFIG.title}`,
    description: 'Enhance your MTG games with a variety of proxy tokens. Fetch and print tokens from a comprehensive collection to add depth to your game.',
    twitter_image: 'https://www.mtg-proxies.com/share-image-twitter.png',
  },
  EMBLEMS: {
    title: `Emblems - ${CONFIG.title}`,
    description: 'Access a selection of MTG emblems for proxy printing. Fetch emblem images easily and add a unique touch to your MTG gameplay.',
    twitter_image: 'https://www.mtg-proxies.com/share-image-twitter.png',
  },
  SEARCH: {
    title: `Search MTG Cards - ${CONFIG.title}`,
    description: 'Effortlessly find and print MTG proxy cards. Use our search tool to access an extensive database and enhance your Magic: The Gathering deck with proxies.',
    twitter_image: 'https://www.mtg-proxies.com/share-image-twitter.png',
  },
  SUPPORT: {
    title: `Support - ${CONFIG.title}`,
    description: 'If you have any questions, feedback, or want a token/emblem added, please send us an email.',
    twitter_image: 'https://www.mtg-proxies.com/share-image-twitter.png',
  },
  BLOG: {
    title: `Articles - ${CONFIG.title}`,
    description: 'Articles written about Magic: The Gathering and proxy cards.',
    twitter_image: 'https://www.mtg-proxies.com/share-image-twitter.png',
  },
  IMPORT: {
    title: `Import - ${CONFIG.title}`,
    description: 'Import your MTG decks from other sites to print your Magic: The Gathering proxies.',
    twitter_image: 'https://www.mtg-proxies.com/share-image-twitter.png',
  },
  TOP_PROXIES: {
    title: `Top Proxies - ${CONFIG.title}`,
    description: 'View the most popular Magic: The Gathering proxy cards.',
    twitter_image: 'https://www.mtg-proxies.com/share-image-twitter.png',
  },
  DECK_LISTS: {
    title: `Deck Lists - ${CONFIG.title}`,
    description: 'View and print proxies for entire Magic: The Gathering deck lists.',
    twitter_image: 'https://www.mtg-proxies.com/share-image-twitter.png',
  },
};

export const ARTICLES = [
  {
    id: 5,
    title: 'The Benefits of Playing with MTG Proxies',
    href: '/articles/benefits-of-playing-with-mtg-proxies',
    description: `MTG Proxies provide a way for players to experience the game without the burden of high card costs. This article dives into the various benefits of using proxies in your casual and playtesting sessions.`,
    date: 'Oct 4, 2024',
    datetime: '2024-10-04',
    image: '/articles/playing.jpg',
  },
  {
    id: 4,
    title: 'Top 10 MTG Proxy Cards for an Unbeatable Deck',
    href: '/articles/top-10-mtg-proxy-cards',
    description: `Proxy cards allow players to experiment with different strategies without the financial burden of collecting rare and expensive cards. In this article, we'll explore the top 10 Magic: The Gathering proxy cards that can give you an edge in building an unbeatable deck.`,
    date: 'Dec 8, 2023',
    datetime: '2023-12-08',
    image: '/articles/jace.jpeg',
  },
  {
    id: 3,
    title: 'The Evolution of MTG Proxies: A Journey from Handmade to High-Quality',
    href: '/articles/evolution-of-mtg-proxies',
    description: 'Explore the fascinating evolution of Magic: The Gathering proxies, tracing their journey from simple handmade versions to advanced, high-quality prints, and uncover the impact on the Magic: The Gathering community and gameplay.',
    date: 'Dec 6, 2023',
    datetime: '2023-12-06',
    image: '/articles/pile-3.jpeg',
  },
  {
    id: 2,
    title: 'Using MTG proxies in Casual Play',
    href: '/articles/using-mtg-proxies-in-casual-play',
    description: 'Explore the ethical aspects of using MTG proxies in casual games. Understand the balance between fun and fairness in using printed proxies.',
    date: 'Nov 19, 2023',
    datetime: '2023-11-19',
    image: '/articles/pile.jpg',
  },
  {
    id: 1,
    title: 'A Step-by-Step Guide to Printing MTG Proxies',
    href: '/articles/step-by-step-guide-to-printing-mtg-proxies',
    description: 'Discover how to print proxies for your MTG decks using MTG Proxies. This guide walks you through the process of searching, selecting, and printing proxies for your MTG deck.',
    date: 'Nov 18, 2023',
    datetime: '2023-11-18',
    image: '/articles/black-lotus.webp',
  },
];
