import { Toaster } from 'react-hot-toast';

import '../styles/globals.css';
import Navbar from '../components/Navbar';
import Cart from '../components/Cart';
import Footer from '../components/Footer';
import { CartProvider } from '../hooks/useCart';
import { PDFProvider } from '../hooks/usePDF';
import { ScryfallProvider } from '../hooks/useScryfall';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

function MyApp({ Component, pageProps }) {
  return (
    <QueryClientProvider client={queryClient}>
      <ScryfallProvider>
        <PDFProvider>
          <CartProvider>
            <div className='flex flex-col min-h-full'>
              <Navbar />
              <main className='flex-grow'>
                <Component {...pageProps} />
              </main>
              <Footer />
            </div>
            <Cart />
            <Toaster position='bottom-center' />
          </CartProvider>
        </PDFProvider>
      </ScryfallProvider>
    </QueryClientProvider>
  );
}

export default MyApp;
