import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

export default function CardImage({ src, alt }) {
  if (!src) return <></>;

  return (
    <div>
      <Zoom zoomMargin={40}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src={src} alt={alt} width='427px' height='595px' loading='lazy' className='rounded-xl' />
      </Zoom>
    </div>
  );
}
