import React from 'react';
import Spinner from './Spinner';
import Link from 'next/link';

export default function Button({ children, type = 'button', onClick, full, unstyled, className, disabled, testId, loading, href }) {
  const classes = `flex items-center text-decoration-none font-medium justify-center px-4 py-2 bg-gray-800 transition-colors duration-150 hover:bg-gray-900 text-gray-50 rounded-md ${full ? 'flex w-full' : 'inline-flex'}`;

  if (href) {
    return (
      <Link href={href} className={`${unstyled ? null : classes} ${className} ${disabled ? 'opacity-50' : ''}`} data-test-id={testId}>
        {children}
      </Link>
    );
  }

  return (
    <button type={type} disabled={disabled} onClick={onClick} className={`${unstyled ? null : classes} ${className} ${disabled ? 'opacity-50' : ''}`} data-test-id={testId}>
      {loading ? <Spinner dimensionClasses='w-5 h-5' /> : <>{children}</>}
    </button>
  );
}
