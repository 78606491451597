import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, useEffect } from 'react';
import Badge from './Badge';

export default function MobileDrawer({ active, setActive }) {
  const router = useRouter();

  useEffect(() => {
    setActive(false);
  }, [router]);

  return (
    <Transition.Root show={active} as={Fragment}>
      <Dialog as='div' className='fixed inset-0 overflow-hidden z-[1000]' onClose={setActive}>
        <div className='absolute inset-0 overflow-hidden'>
          <Dialog.Overlay className='absolute inset-0' />

          <div className='fixed inset-y-0 right-0 flex max-w-full'>
            <Transition.Child
              as={Fragment}
              enter='transform transition ease-in-out duration-500 sm:duration-700'
              enterFrom='translate-x-full'
              enterTo='translate-x-0'
              leave='transform transition ease-in-out duration-500 sm:duration-700'
              leaveFrom='translate-x-0'
              leaveTo='translate-x-full'
            >
              <div className='w-screen max-w-md'>
                <div className='flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl'>
                  <div className='px-4 sm:px-6'>
                    <div className='flex items-start justify-between'>
                      <Dialog.Title className='text-lg font-medium text-gray-900'></Dialog.Title>
                      <div className='flex items-center ml-3 h-7'>
                        <button type='button' className='text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500' onClick={() => setActive(false)}>
                          <span className='sr-only'>Close panel</span>
                          <XIcon className='w-6 h-6' aria-hidden='true' />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='relative'>
                    <nav className='px-8'>
                      <ul className='flex flex-col space-y-8'>
                        <li>
                          <Link href='/search' className={`${router.pathname == '/search' ? 'text-green-700' : ''} font-medium text-xl`}>
                            Search
                          </Link>
                        </li>
                        <li>
                          <Link href='/sets' className={`${router.pathname == '/sets' ? 'text-green-700' : ''} font-medium text-xl`}>
                            Sets
                          </Link>
                        </li>
                        <li>
                          <Link href='/tokens' className={`${router.pathname == '/tokens' ? 'text-green-700' : ''} font-medium text-xl`}>
                            Tokens
                          </Link>
                        </li>
                        <li>
                          <Link href='/emblems' className={`${router.pathname == '/emblems' ? 'text-green-700' : ''} font-medium text-xl`}>
                            Emblems
                          </Link>
                        </li>
                        <li>
                          <Link href='/articles' className={`${router.pathname == '/articles' ? 'text-green-700' : ''} font-medium text-xl`}>
                            Articles
                          </Link>
                        </li>
                        <li className='flex items-centers gap-2'>
                          <Link href='/import' className={`${router.pathname == '/import' ? 'text-green-700' : ''} font-medium text-xl`}>
                            Import
                          </Link>
                        </li>
                        {process.env.NEXT_PUBLIC_DECK_LISTS_LIVE === 'true' && (
                          <li className='flex items-centers gap-2'>
                            <Link href='/deck-lists' className={`${router.pathname == '/deck-lists' ? 'text-green-700' : ''} font-medium text-xl`}>
                              Deck Lists
                            </Link>
                            <Badge>New</Badge>
                          </li>
                        )}
                        {process.env.NEXT_PUBLIC_TOP_PROXIES_LIVE === 'true' && (
                          <li className='flex items-centers gap-2'>
                            <Link href='/top-proxies' className={`${router.pathname == '/top-proxies' ? 'text-green-700' : ''} font-medium text-xl`}>
                              Top Proxies
                            </Link>
                            <Badge>New</Badge>
                          </li>
                        )}
                        <li>
                          <Link href='/support' className={`${router.pathname == '/support' ? 'text-green-700' : ''} font-medium text-xl`}>
                            Support
                          </Link>
                        </li>
                        <li>
                          {/* <Link href='https://www.buymeacoffee.com/zacminner'>
                            <a className='inline-flex items-center justify-center px-3 py-2 font-medium transition-colors duration-150 border border-gray-800 rounded-md hover:bg-gray-900 hover:text-gray-50' target='_blank'>
                              Donate
                            </a>
                          </Link> */}

                          <Link
                            href='https://www.buymeacoffee.com/zacminner'
                            className='flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white bg-green-600 border border-transparent rounded-md hover:bg-green-700 md:py-4 md:text-lg md:px-10'
                            target='_blank'
                          >
                            Donate
                          </Link>
                        </li>
                      </ul>
                    </nav>
                    {/* /End replace */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
