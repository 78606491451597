import React, { useState } from 'react';
import { PlusIcon } from '@heroicons/react/outline';
import { MinusIcon } from '@heroicons/react/outline';
import { AnimatePresence, motion } from 'framer-motion';
import { useCart } from '../hooks/useCart';
import Button from './Button';
import CardImage from './CardImage';

export default function Card({ card }) {
  const { addCardToCart, updateQuantity, getQuantityOfItem } = useCart();
  const cardId = card?.id;
  const imageSrc = card?.image_uris?.large;
  const cardName = card?.name;
  const setName = card?.set_name;
  const set = card?.set;
  const quantity = getQuantityOfItem(cardId);

  const increment = () => {
    if (quantity === 0) {
      addCardToCart(card);
    } else {
      updateQuantity(cardId, 1);
    }
  };

  const decrement = () => {
    updateQuantity(cardId, -1);
  };

  const tran = {
    type: '',
  };

  const wrapperMotion = {
    rest: {
      width: '36px',
      transition: { ...tran },
    },
    active: {
      width: '144px',
      transition: { ...tran },
    },
  };

  const addToCartTextMotion = {
    rest: { maxWidth: 0, opacity: 0 },
    active: {
      maxWidth: '400px',
      opacity: 1,
    },
  };

  return (
    <div className='relative w-full space-y-2'>
      <div className='space-y-1'>
        <div className='relative'>
          <div className='absolute bottom-[12px] right-[6px] z-20'>
            <motion.div
              initial={quantity > 0 ? 'active' : 'rest'}
              animate={quantity > 0 ? 'active' : 'rest'}
              variants={wrapperMotion}
              className={`flex items-center justify-between px-1 h-9 bg-green-50 rounded-full drop-shadow-lg ${quantity > 0 ? 'w-[144px]' : 'w-auto'}`}
            >
              {quantity > 0 && (
                <>
                  <Button unstyled className='flex items-center justify-center w-9 h-9' testId={`card-decrement-${cardId}`} onClick={decrement}>
                    <span className='sr-only'>Remove one {cardName} from print list</span>
                    <MinusIcon width={22} />
                  </Button>
                  <div className='text-sm font-semibold' data-test-id={`card-quantity-${cardId}`}>
                    {quantity}
                  </div>
                </>
              )}

              <Button unstyled className='relative flex items-center justify-center w-9 h-9' testId={`card-increment-${cardId}`} onClick={increment}>
                <span className='sr-only'>Add {cardName} to print list</span>
                <div className='flex-shrink-0'>
                  <PlusIcon width={22} />
                </div>
              </Button>
            </motion.div>
          </div>
          <CardImage src={imageSrc} alt={cardName} />
        </div>
        <div>
          <h3>{cardName}</h3>
          <p className='text-xs text-gray-600'>{setName}</p>
        </div>
      </div>
    </div>
  );
}
