import { TrashIcon, PrinterIcon } from '@heroicons/react/outline';
import React, { createContext, useContext, useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { STORAGE_KEYS } from '../config';
import { logEvent } from '../utilities/ga';
import usePDF from './usePDF';
import useStorage from './useStorage';

const CartContext = createContext(null);
export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cartOpened, setCartOpened] = useState(false);
  const [cart, setCart] = useState([]);
  const { storage } = useStorage();
  const { setDownloadStatus } = usePDF();

  const getCartFromStorage = async () => {
    const cart = await storage.getItem(STORAGE_KEYS.CART);
    if (cart) {
      setCart(cart);
    }
  };

  const saveCartToStorage = async () => {
    await storage.setItem(STORAGE_KEYS.CART, cart);
  };

  const clearCart = async () => {
    logEvent({
      action: 'clearCart',
      params: {
        numberOfCards: getNumberOfItemsInCart(),
      },
    });
    setCart([]);
    toast.success(`Successfully cleared print list`);
    setDownloadStatus(null);
  };

  const openCart = () => {
    setCartOpened(true);
    logEvent({
      action: 'openCart',
    });
  };

  const closeCart = () => {
    setCartOpened(false);
    logEvent({
      action: 'closeCart',
    });
    setDownloadStatus(null);
  };

  const updateQuantity = (cardId, quantity) => {
    logEvent({
      action: 'updateQuantity',
      params: {
        cardId,
        quantity,
      },
    });

    setCart((theCart) =>
      theCart
        .map((it) => {
          if (it?.details?.id !== cardId) {
            return it;
          }
          return { ...it, quantity: it.quantity + quantity };
        })
        .filter((it) => it && it.quantity)
    );
  };

  const addCardToCart = (card) => {
    setCart((theCart) => [...theCart, { quantity: 1, details: card }]);
    toast.success(`Added ${card?.name}`);
    logEvent({
      action: 'addCardToCart',
      params: {
        cardId: card.id,
        cardName: card.name,
        quantity: 1,
      },
    });
  };

  const addCardsToCart = (cards) => {
    setCart((theCart) => {
      const updatedCart = [...theCart];

      cards.forEach((newCard) => {
        const existingCardIndex = updatedCart.findIndex((cartItem) => cartItem.details.id === newCard.details.id);

        if (existingCardIndex !== -1) {
          // If the card exists, update the quantity
          updatedCart[existingCardIndex].quantity += newCard.quantity;
        } else {
          // If the card does not exist, add it to the cart
          updatedCart.push(newCard);
        }
      });

      return updatedCart;
    });

    logEvent({
      action: 'addCardsToCart',
      params: {
        numberOfCards: cards.length,
      },
    });
  };

  const getQuantityOfItem = (cardId) => {
    const [item] = cart.filter((it) => it?.details?.id === cardId);
    if (item) return item.quantity;
    return 0;
  };

  const getNumberOfItemsInCart = () => {
    let quantity = 0;
    for (const it of cart) {
      quantity += it?.quantity || 0;
    }
    return quantity;
  };

  useEffect(() => {
    if (!storage) return;
    getCartFromStorage();
  }, [storage]);

  useEffect(() => {
    if (!storage) return;
    saveCartToStorage(cart);
  }, [storage, cart]);

  return (
    <CartContext.Provider
      value={{
        cartOpened,
        openCart,
        closeCart,
        cart,
        updateQuantity,
        addCardToCart,
        clearCart,
        getNumberOfItemsInCart,
        getQuantityOfItem,
        addCardsToCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
